import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/dashboard-layout/DashboardLayout";
import styles from "./BarangMasuk.module.css";
import {
  message,
  DatePicker,
  Table,
  Modal,
  Tooltip,
  Breadcrumb,
  Select,
  Button,
  Input,
} from "antd";
import { ExclamationCircleOutlined, HomeOutlined } from "@ant-design/icons";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { BASE_API_URL } from "../../../constant/url";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import Search from "antd/es/input/Search";
// import { Table } from "react-bootstrap";
const { RangePicker } = DatePicker;
const onChange = (value, dateString) => {
  console.log("Selected Time: ", value);
  console.log("Formatted Selected Time: ", dateString);
};
const onOk = (value) => {
  console.log("onOk: ", value);
};
const { confirm } = Modal;
function BarangMasuk() {
  const [loading, setLoading] = useState(false);
  const [getAllLeave, setGetAllLeave] = useState([]);
  const acccesToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [startIndex, setStartIndex] = useState(1);
  const [getAllListBarangRegister, setGetAllListBarangRegister] = useState([]);
  const [getAllInventory, setGetAllInventory] = useState([]);
  const [getAllCategory, setGetAllCategory] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [messageVal, setMessageVal] = useState("");
  const [searchTerm, setSearchTerm] = useState([]);
  const [statusSelected, setStatusSelected] = useState("all");
  const [dataCommitment, setDataCommitment] = useState([]);
  const [ItemCategorySelected, setItemCategorySelected] = useState("all");
  const [searchTerm2, setSearchTerm2] = useState([]);
  const [costCenterSelected, setCostCenterSelecte] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };

  const formatRupiah = (value) => {
    return value
      .toString()
      .split("")
      .reverse()
      .join("")
      .match(/\d{1,3}/g)
      .join(".")
      .split("")
      .reverse()
      .join("");
  };

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => <span>{startIndex + index}</span>,
    },
    {
      title: "Tanggal",
      dataIndex: "date",
      key: "date",
      render: (startDate) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(startDate).toLocaleDateString('id-ID', options);
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Nama Barang/Spare Part",
      dataIndex: "inventoryname",
      key: "inventoryname",
    },
    // {
    //   title: "Kategori",
    //   dataIndex: "category",
    //   key: "category",
    // },
    {
      title: "NO P.O",
      dataIndex: "ponumber",
      key: "ponumber",
    },
    {
      title: "Jumlah",
      dataIndex: "amount",
      key: "amount",
      align: 'right',
      // render: (amount) => {
      //   const parsedAmount =
      //     typeof amount === "string" ? parseFloat(amount) : amount;

      //   const formattedTotal = parsedAmount.toLocaleString(undefined, {
      //     minimumFractionDigits: 2,
      //     maximumFractionDigits: 2,
      //   });

      //   return <span>{formattedTotal}</span>;
      // },

      // render: (amount) => {
      //   const parsedAmount =
      //     typeof amount === "string" ? parseFloat(amount) : amount;
      //   const formattedTotal = parsedAmount.toFixed(3);
      //   return <span>{formattedTotal}</span>;
      // },
      render: (amount) => {
        const formattedTotal = parseFloat(amount).toLocaleString();
        return <span>{formattedTotal}</span>;
      },

      // const formattedTotal = new Intl.NumberFormat('id-ID', {
      //   minimumFractionDigits: 2,
      //   maximumFractionDigits: 2,
      // }).format(amount);
      // return <span>{formattedTotal}</span>;

      // render: (amount) => {
      //   const formattedTotal = new Intl.NumberFormat("id-ID").format(amount);
      //   return <span>{formattedTotal}</span>;
      // },
    },
    {
      title: "Satuan",
      dataIndex: "unit",
      key: "unit",
      align: 'right',
    },
    {
      title: "Harga Satuan",
      dataIndex: "unitprice",
      key: "unitprice",
      render: (_, record) => `Rp. ${formatRupiah(record.unitprice)}`,
      align: 'right',
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Action",
      key: "aksi",
      render: (_, record) => {
        if (getAllListBarangRegister.length >= 1) {
          return (
            <div className={styles.btngroup}>
              {/* <NavLink to={`/admin/detail-admin/${record.id}`}>
                <Tooltip placement="bottom" title={`Click to view admin`}>
                  <AiFillEye className={styles.iconActionView} />
                </Tooltip>
              </NavLink> */}
              <NavLink
                to={`/barangMasuk/edit-barang/${record.id}`}
                className={styles.btnEditCustomer}
              >
                <AiFillEdit />
              </NavLink>
              {/* <button
                className={styles.btnDeleteAdmin}
                onClick={() => deleteItems(record.id)}
              >
                <AiFillDelete />
              </button> */}
            </div>
          );
        }
      },
    },
  ];
  // console.log("kolom", columns);

  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/registerItem/list-items`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllListBarangRegister(response.data.data);
        setSearchTerm(response.data.data);
        console.log(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  //List Inventories
  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/inventory/list-inventory`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllInventory(response.data.data);
        console.log("pengelyara", response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  //List kategori
  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/sparepart/list-sparepart-category`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllCategory(response.data.data);
        setSearchTerm2(response.data.data);
        console.log("catgori", response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  const listStatus = getAllCategory.map((category) => ({
    label: category.itemcategory,
    value: category.id,
  }));

  const deleteItems = (value) => {
    confirm({
      title: "Are you sure you want to delete the items ?",
      icon: <ExclamationCircleOutlined />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        let config = {
          method: "delete",
          url: `${BASE_API_URL}/v1/registerItem/deleteitems/${value}`,
          headers: {
            Authorization: `Bearer ${acccesToken}`,
            "Content-Type": "application/json",
          },
        };
        axios(config)
          .then(function (response) {
            message.success("Delete Items successfully");
            window.location.reload();
          })
          .catch(function (error) {
            message.error("Delete Items Failed");
            if (error.response.status === 401) {
              logout();
              message.error("Your session has expired, please log in again");
            }
            setLoading(false);
          });
      },
      onCancel() { },
    });
  };

  const handledownload = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_API_URL}/v1/registerItem/register-item-excel`,
        { startDate, endDate },

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          responseType: "blob",
        }
      );

      // Create a Blob from the binary data
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "BarangMasuk.xlsx";
      document.body.appendChild(link);

      link.click();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 401) {
        logout();
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response && error.response.data.statusCode === 4002) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response && error.response.status === 400) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else {
        message.error("Unknown Error");
      }
      console.log(error);
    }
  };

  const mergedData = getAllListBarangRegister.map((registerItem) => {
    const inventoryItem = getAllInventory.find(
      (inventory) =>
        String(inventory.id) === String(registerItem.inventories_id)
    );

    return {
      ...registerItem,
      inventoryname: inventoryItem ? inventoryItem.inventoryname : null,
    };
  });

  mergedData.sort((a, b) => new Date(b.date) - new Date(a.date));

  const handleChangeSearch = (e) => {
    const searchText = e.target.value.toLowerCase();

    if (searchText === "") {
      setGetAllListBarangRegister(searchTerm);
    } else {
      const filteredData = getAllListBarangRegister
        .map((registerItem) => {
          const inventoryItem = getAllInventory.find(
            (inventory) =>
              String(inventory.id) === String(registerItem.inventories_id)
          );

          return {
            ...registerItem,
            inventoryname: inventoryItem ? inventoryItem.inventoryname : null,
          };
        })
        .filter((item) =>
          Object.values(item).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchText)
          )
        );

      setGetAllListBarangRegister(filteredData);
    }
  };

  const handleFilterStatus = (e) => {
    setStatusSelected(e);

    if (e === "all") {
      setDataCommitment(searchTerm2);
    } else {
      let filteredData;

      if (dataCommitment !== "all") {
        filteredData = searchTerm2.filter(
          (item) =>
            item.itemcategory.toUpperCase() === dataCommitment.toUpperCase()
        );
      } else {
        filteredData = searchTerm2.filter(
          (item) => item.itemcategory.toUpperCase() === e.toUpperCase()
        );
      }
      setDataCommitment(filteredData);
    }
  };

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  console.log("kategori", getAllCategory);
  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <div className={styles.main}>
          <div className={styles.pageInfo}>
            <h3 className={styles.pageInfoTitle}>Barang Masuk</h3>
            <Breadcrumb
              className={styles.breadcumbs}
              items={[
                {
                  href: "/dashboard",
                  title: <HomeOutlined />,
                },
                {
                  href: "/barangMasuk",
                  title: <span>Barang Masuk</span>,
                },
              ]}
            />
          </div>
          <div className={styles.wrapperMenu}>
            <div className={styles.tableMenu}>
              <div className={styles.searchBox}>
                <Search
                  placeholder="Masukkan kata kunci pencarian"
                  allowClear
                  enterButton="Cari"
                  size="large"
                  onChange={handleChangeSearch}
                  style={{ width: 400 }}
                />
              </div>
              {/* <Select
                defaultValue="Semua"
                style={{
                  width: 250,
                  height: 35,
                }}
                // onChange={handleFilterStatus}
                options={listStatus}
                placeholder="Filter berdasarkan status"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              ></Select> */}
              <div className={styles.containerButton}>
                <div className={styles.buttonMenu1}>
                  <NavLink
                    to="/barangMasuk/add-barang"
                    className={styles.btnAddCustomer}
                  >
                    Tambah Data
                  </NavLink>
                </div>
                <div className={styles.wrapperButton}>
                  <Button className={styles.btnAddJobs} type="primary" onClick={showModal}>
                    Export Data
                  </Button>
                  <Modal
                    className={styles.modalexport}
                    open={open}
                    title="Export Data"
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={(_, { OkBtn, CancelBtn }) => (
                      <>
                        <Button onClick={handledownload}>Export Data</Button>
                      </>
                    )}
                  >
                    <div className={styles.wrapperdate}>
                      <div className={styles.wrapperdatetop}>
                        <p className={styles.textdate}>Mulai Tanggal</p>
                        <Input className={styles.inputdate} type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                      </div>
                      <div className={styles.wrapperdatebottom}>
                        <p className={styles.textdate}>Sampai Tanggal</p>
                        <Input className={styles.inputdate} type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
            <Table
              className={styles.tableCustomers}
              bordered
              dataSource={mergedData}
              columns={columns}
              pagination={{
                pageSize: 10,
                onChange: (page, pageSize) => {
                  setStartIndex((page - 1) * pageSize + 1);
                },
              }}
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default BarangMasuk;
