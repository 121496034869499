import React, { useEffect, useState } from "react";
import styles from "./AddPemasukanBahanBaku.module.css";
import { Alert, Input, Select, message } from "antd";
import DashboardLayout from "../../../../layouts/dashboard-layout/DashboardLayout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_API_URL } from "../../../../constant/url";
function AddPemasukanBarangMasuk() {
  const acccesToken = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  const [getAllInventory, setGetAllInventory] = useState([]);
  const [selectedBarang, setSelectedBarang] = useState(null);
  const [spending, setSpending] = useState({
    raw_material_date: "",
    raw_material_total: "",
    raw_material_unit: "",
    commitment_raw_material_id: ""
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [messageVal, setMessageVal] = useState("");
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };

  //handle change
  const handleChange = (event) => {
    setSpending({
      ...spending,
      [event.target.name]: event.target.value,
    });
  };

  const handleBarangChange = (value) => {
    setSelectedBarang(value);
    setSpending({
      ...spending,
      commitment_raw_material_id: value,
    });
  };

  //handle input
  const handleInsertNewSpending = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_API_URL}/v1/raw-material/commitment-out`,
        spending,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      message.success("Berhasil Membuat Komitmen");
      setLoading(false);
      setTimeout(() => {
        navigate("/pemasukan-bahan-baku");
      }, 500);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        logout();
        // message.error("sesi anda telah habis, login ulang");
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
      } else if (error.response.data.statusCode == 4002) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Email telah terdaftar");
      } else if (error.response.status === 400) {
        setOpenAlert(true);
        setMessageVal(`${error.response.data.message}`);
        // message.error("Pastikan semua data telah terisi");
      } else {
        message.error("Unknown Error");
      }
      console.log(error);
    }
  };

  //list barang
  useEffect(() => {
    setLoading(true);
    let config = {
      method: "get",
      url: `${BASE_API_URL}/v1/commitment-raw_material/list-commitment`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllInventory(response.data.data);
        console.log("commitment_raw", response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response && error.response.status === 401) {
          logout();
          message.error("Your session has expired, please log in again");
        }
        setLoading(false);
      });
  }, []);

  return (
    <DashboardLayout>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h4 className={styles.addAdminTitle}>Add Data</h4>
          <div className={styles.form}>
            <div className={styles.alertLogin}>
              {openAlert && (
                <Alert
                  className={styles.alertText}
                  message={messageVal}
                  type="error"
                  showIcon
                  closable
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                />
              )}
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="companyname" className={styles.formLabel}>
                Nama Bahan Baku
              </label>
              <Select
                className={styles.formControl}
                showSearch
                placeholder="Pilih barang"
                optionFilterProp="children"
                onChange={handleBarangChange}
                value={selectedBarang}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={getAllInventory.map((item) => ({
                  value: item.id,
                  label: item.commitment_raw_material_name,
                }))}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Tanggal Keluar
              </label>
              <Input
                required="true"
                type="date"
                name="raw_material_date"
                value={spending.raw_material_date}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Masukkan Jumlah Barang"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Jumlah
              </label>
              <Input
                required="true"
                type="text"
                // step="0.01"
                name="raw_material_total"
                value={spending.raw_material_total}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Masukkan Jumlah Bahan Bakug"
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="attnC" className={styles.formLabel}>
                Satuan
              </label>
              <Input
                required="true"
                type="text"
                name="raw_material_unit"
                value={spending.raw_material_unit}
                onChange={handleChange}
                className={styles.formControl}
                placeholder="Masukkan Jenis Satuan Bahan Baku"
              />
            </div>
            <div className={styles.btnBox}>
              {loading ? (
                <button className={styles.btnAdd}>Loading...</button>
              ) : (
                <button
                  className={styles.btnAdd}
                  onClick={handleInsertNewSpending}
                >
                  Tambah Data
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default AddPemasukanBarangMasuk;
