import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../layouts/dashboard-layout/DashboardLayout";
import styles from "./DetailPersediaan.module.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  Input,
  Breadcrumb,
  message,
  Alert,
  Select,
  Radio,
  DatePicker,
  Space,
  Skeleton,
  Table,
  Tooltip,
  Modal,
  Tag,
} from "antd";
import axios from "axios";
import { BASE_API_URL } from "../../../../constant/url";
function DetailPersediaan() {
  const role = localStorage.getItem("role");
  const acccesToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [startIndex, setStartIndex] = useState(1);
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    message.error("Logout berhasil");
    navigate("/");
  };
  const [loading, setLoading] = useState(false);
  const [getAllSpending, setGetAllSpending] = useState([]);
  const params = useParams();
  const { id } = params;

  const { Column, ColumnGroup } = Table;

  useEffect(() => {
    setLoading(true);

    var config = {
      method: "get",
      url: `${BASE_API_URL}/v1/inventory/detailinventory/${id}`,
      headers: {
        Authorization: `Bearer ${acccesToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        setGetAllSpending(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response && error.response.status) {
          if (error.response.status === 401) {
            logout();
            message.error("Your session has expired. Please login again.");
          } else if (error.response.status == 404) {
            // Handle 404 error
          } else if (error.response.status === 403) {
            message.error("Access denied");
          } else {
            message.error("Unknown Error");
          }
        } else {
          // Handle other types of errors here
          console.log(error);
        }
      });
  }, []);

  return (
    <DashboardLayout>
      <h3 className={styles.titleWrapper}>Detail Pengeluaran</h3>
      <div className={styles.mainDetail}>
        <div className={styles.mainUserDetail}>
          <div className={styles.mainDetailData}>
            <div className={styles.mainLeftData}>
              <p className={styles.titleDetail}>PT. KALTIM INDUSTRIAL ESTATE</p>
            </div>
          </div>
          <div className={styles.mainDetailData}>
            <div className={styles.mainLeftData}>
              <p className={styles.titleDetail}>Deskripsi</p>
            </div>
            <div className={styles.mainRightData}>
              <p className={styles.textDetail}>
                : {getAllSpending.descriptionproduk}
              </p>
            </div>
          </div>
          <div className={styles.mainDetailData}>
            <div className={styles.mainLeftData}>
              <p className={styles.titleDetail}>Periode</p>
            </div>
            <div className={styles.mainRightData}>
              <p className={styles.textDetail}>: {getAllSpending.periode} </p>
            </div>
          </div>
          <div className={styles.mainDetailData}>
            <div className={styles.mainLeftData}>
              <p className={styles.titleDetail}>Lokasi</p>
            </div>
            <div className={styles.mainRightData}>
              <p className={styles.textDetail}>: {getAllSpending.location} </p>
            </div>
          </div>
          <div className={styles.mainDetailData}>
            <div className={styles.mainLeftData}>
              <p className={styles.titleDetail}>Satuan</p>
            </div>
            <div className={styles.mainRightData}>
              <p className={styles.textDetail}>: {getAllSpending.unit}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.buttonMenu}>
        <NavLink
          to={`/persediaan/detail-persediaan/add-detail-persediaan`}
          className={styles.btnAddCustomer}
        >
          Tambah Persediaan
        </NavLink>
      </div>
      {/* <Table dataSource={getAllSpending}>
        <Column title="No" dataIndex="no" key="no" fixed="left" />
        <Column title="Tanggal" dataIndex="tanggal" key="tanggal" />
        <Column title="Vendor/Lokasi" dataIndex="vendor" key="vendor" />
        <ColumnGroup title="Barang Masuk">
          <Column title="No PO" dataIndex="NPBM" key="NPBM" />
          <Column title="Volume" dataIndex="volumebm" key="volumebm" />
          <Column title="Harga" dataIndex="hargabm" key="hargabm" />
        </ColumnGroup>
        <ColumnGroup title="Barang Keluar">
          <Column title="No MIR" dataIndex="NoMIR" key="NoMIR" />
          <Column title="Volume" dataIndex="volumebk" key="volumebk" />
          <Column title="Harga Satuan" dataIndex="hargabk" key="hargabk" />
          <Column title="Nilai" dataIndex="nilaibk" key="nilaibk" />
        </ColumnGroup>
        <ColumnGroup title="Saldo Akhir">
          <Column title="Volume" dataIndex="volumesa" key="volumesa" />
          <Column title="Harga Satuan" dataIndex="hargasa" key="hargasa" />
          <Column title="Nilai" dataIndex="nilaisa" key="nilaisa" />
        </ColumnGroup>
        <Column
          title="Action"
          key="action"
          render={(_, record) => (
            <Space size="middle">
              <a>Invite {record.lastName}</a>
              <a>Delete</a>
            </Space>
          )}
        />
      </Table> */}
    </DashboardLayout>
  );
}

export default DetailPersediaan;
